<template>
	<component
		:is="nodeType"
		class="card"
		v-bind="{ to, ...(!!href && !isNuxt && { href }) }"
		:class="[
			{ dashed },
			{ border },
			{ loading },
			{ selected },
			{ dark },
			shadow && `shadow-${shadowSize}`,
			hover && `hover-${hoverSize}`,
			`border-radius-${borderRadius}`,
			color && `${color}`,
			elevation && `elevation-${elevation}`,
		]"
		:style="style"
		@click="$emit('click')"
	>
		<div
			v-if="$slots['badge']"
			:class="`badge-${borderRadius}`"
		>
			<!-- @slot Slot for badge, placement top uppler-left -->
			<slot name="badge" />
		</div>
		<!-- @slot Slot for card content -->
		<slot />
	</component>
</template>

<script>
import { defineComponent, resolveComponent } from "vue";

import variables from "@/assets/tokens/json/variables.json";
import borderRadius from "./definitions/borderRadius";

import { useContext } from "unctx";
const isNuxt = () => !!useContext("nuxt-app");

/**
 * Card is a wrapper component that contains a slot for custom markup
 * @displayName TnCard
 */
export default defineComponent({
	name: "TnCard",

	props: {
		/**
		 * The background-color of the card
		 */
		color: {
			type: String,
		},
		/**
		 * The card has a dashed border effect or not
		 */
		dashed: {
			type: Boolean,
			default: false,
		},
		/**
		 * The size of the hover-effect for the card
		 * @values s, m, l
		 */
		hover: {
			type: [Boolean, String],
			default: null,
		},
		/**
		 * The size of the box-shadow for the card
		 * @values s, m, l
		 */
		shadow: {
			type: [Boolean, String],
			default: null,
		},
		/**
		 * The card has a border or not
		 */
		border: {
			type: Boolean,
			default: false,
		},
		/**
		 * The min height of the card
		 */
		minHeight: {
			type: [Number, String],
			default: null,
		},
		/**
		 * The max height of the card
		 */
		maxHeight: {
			type: [Number, String],
			default: null,
		},
		/**
		 * The min width of the card
		 */
		minWidth: {
			type: [Number, String],
			default: null,
		},
		/**
		 * The max width of the card
		 */
		maxWidth: {
			type: [Number, String],
			default: null,
		},
		/**
		 * The min width of the card
		 */
		width: {
			type: [Number, String],
			default: null,
		},
		/**
		 * The size of the border-radius for the card
		 * @values s, m, l
		 */
		borderRadius: {
			type: String,
			default: "s",
			validator: function (value) {
				return borderRadius.includes(value.toLowerCase());
			},
		},
		/**
		 * Show a loading animation
		 */
		loading: {
			type: Boolean,
			default: false,
		},
		/**
		 * The card is selected or not
		 */
		selected: {
			type: Boolean,
			default: false,
		},
		/**
		 * to for nuxt-link/vue router-link
		 */
		to: {
			type: [String, Object],
			default: null,
		},
		/**
		 * anchor
		 */
		href: {
			type: String,
			default: undefined,
		},
		dark: {
			type: Boolean,
			default: false,
		},
		elevation: {
			type: String,
			default: null,
			validator: function (value) {
				return borderRadius.includes(value.toLowerCase());
			},
		},
	},

	computed: {
		isNuxt() {
			return isNuxt();
		},
		nodeType() {
			if ((this.to || this.href) && isNuxt()) {
				return resolveComponent("NuxtLink");
			} else if (this.to && this.$router) {
				return "RouterLink";
			} else if (this.to || this.href) {
				return "a";
			} else {
				return "div";
			}
		},
		style() {
			return {
				maxHeight: this.maxHeight && this.maxHeight + "px",
				minHeight: this.minHeight && this.minHeight + "px",
				minWidth: this.minWidth && this.minWidth + "px",
				maxWidth: this.maxWidth && this.maxWidth + "px",
				width: this.width && this.width + "px",
				...(this.color && (this.color.includes("#") || variables[this.color?.trim().toLowerCase()])
					? {
							backgroundColor: variables[this.color?.trim().toLowerCase()] || this.color,
						}
					: {}),
			};
		},
		shadowSize() {
			if (this.border || this.dashed) {
				return "";
			}
			if (typeof this.shadow === "boolean") {
				return "m";
			}
			return this.shadow;
		},
		hoverSize() {
			if (typeof this.hover === "boolean") {
				return "m";
			}
			return this.hover;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/global-style/scss/mixins/all" as mixins;

a.card {
	text-decoration: inherit;
	color: inherit;
	cursor: pointer;

	&:active {
		outline: 1px solid var(--card-active-outline-color) !important;
		background-color: var(--card-active-background-color) !important;
		box-shadow: none !important;
	}

	&:focus-visible {
		outline: 2px solid var(--card-focus-outline-color) !important;
		background-color: var(--card-focus-background-color) !important;
	}
}

.card {
	--card-foreground-color: #{variables.$color-neutrals-black};
	--card-background-color: #{variables.$color-neutrals-white};
	--card-border-color: #{variables.$color-neutrals-200-tint};
	--card-focus-outline-color: #{variables.$color-cta-focus};
	--card-focus-background-color: #{variables.$color-neutrals-white};
	--card-active-outline-color: #{variables.$color-neutrals-50-tint};
	--card-active-background-color: #{variables.$color-neutrals-white};
	--card-hover-background-color: #{variables.$color-neutrals-white};

	font-family: variables.$font-family-telenor-base;
	box-sizing: border-box;
	display: flex;
	transition:
		transform 0.1s linear,
		box-shadow 0.1s linear;
	position: relative;
	overflow: hidden;
	background-color: var(--card-background-color);

	@include mixins.loading;

	&.hover {
		&-s:hover,
		&-m:hover,
		&-l:hover {
			background-color: var(--card-hover-background-color);
		}

		&-s:hover {
			@include mixins.shadow-hover(s);
		}

		&-m:hover {
			@include mixins.shadow-hover(m);
		}

		&-l:hover {
			@include mixins.shadow-hover(l);
		}
	}

	&.blue {
		--card-background-color: #{variables.$color-primary-superlight};
		--card-hover-background-color: #{variables.$color-primary-superlight};
		--card-active-background-color: #{variables.$color-primary-superlight};
	}

	&.dark {
		--card-foreground-color: #{variables.$color-neutrals-white};
		--card-background-color: #{variables.$color-neutrals-1000-shade};
		--card-border-color: #{variables.$color-neutrals-700-shade};
		--card-active-background-color: #{variables.$color-neutrals-900-shade};
		--card-active-outline-color: #{variables.$color-cta-dark-active};
		--card-focus-outline-color: #{variables.$color-cta-dark-focus};
		--card-focus-background-color: #{variables.$color-neutrals-1000-shade};
		--card-hover-background-color: #{variables.$color-neutrals-900-shade};

		&.dark.blue {
			--card-background-color: #{variables.$color-neutrals-900-shade};
			--card-hover-background-color: #{variables.$color-neutrals-800-shade};
			--card-active-background-color: #{variables.$color-neutrals-800-shade};
			--card-focus-background-color: #{variables.$color-neutrals-900-shade};
		}

		&.border {
			--card-forecround-color: #{variables.$color-neutrals-white};
			--card-background-color: #{variables.$color-neutrals-black};
			--card-border-color: #{variables.$color-neutrals-700-shade};
			--card-active-background-color: #{variables.$color-neutrals-1000-shade};
			--card-active-outline-color: #{variables.$color-cta-dark-active};
			--card-focus-background-color: #{variables.$color-neutrals-black};
			--card-hover-background-color: #{variables.$color-neutrals-1000-shade};

			&.elevation-m {
				--card-forecround-color: #{variables.$color-neutrals-white};
				--card-background-color: #{variables.$color-neutrals-1000-shade};
				--card-border-color: #{variables.$color-neutrals-600-shade};
				--card-active-background-color: #{variables.$color-neutrals-900-shade};
				--card-active-outline-color: #{variables.$color-cta-dark-active};
				--card-focus-background-color: #{variables.$color-neutrals-1000-shade};
				--card-hover-background-color: #{variables.$color-neutrals-900-shade};
			}

			&.elevation-l {
				--card-forecround-color: #{variables.$color-neutrals-white};
				--card-background-color: #{variables.$color-neutrals-900-shade};
				--card-border-color: #{variables.$color-neutrals-500-core};
				--card-active-background-color: #{variables.$color-neutrals-800-shade};
				--card-active-outline-color: #{variables.$color-cta-dark-active};
				--card-focus-background-color: #{variables.$color-neutrals-900-shade};
				--card-hover-background-color: #{variables.$color-neutrals-800-shade};
			}
		}

		&.elevation-s:not(.border) {
			--card-foreground-color: #{variables.$color-neutrals-white};
			--card-background-color: #{variables.$color-neutrals-1000-shade};
			--card-border-color: #{variables.$color-neutrals-700-shade};
			--card-active-background-color: #{variables.$color-neutrals-900-shade};
			--card-active-outline-color: #{variables.$color-cta-dark-active};
			--card-focus-outline-color: #{variables.$color-cta-dark-focus};
			--card-focus-background-color: #{variables.$color-neutrals-1000-shade};
			--card-hover-background-color: #{variables.$color-neutrals-900-shade};
		}

		&.elevation-m:not(.border) {
			--card-foreground-color: #{variables.$color-neutrals-white};
			--card-background-color: #{variables.$color-neutrals-900-shade};

			// --card-border-color: #{variables.$color-neutrals-700-shade};

			--card-active-background-color: #{variables.$color-neutrals-800-shade};
			--card-active-outline-color: #{variables.$color-cta-dark-active};
			--card-focus-outline-color: #{variables.$color-cta-dark-focus};
			--card-focus-background-color: #{variables.$color-neutrals-900-shade};
			--card-hover-background-color: #{variables.$color-neutrals-800-shade};
		}

		&.elevation-l:not(.border) {
			--card-foreground-color: #{variables.$color-neutrals-white};
			--card-background-color: #{variables.$color-neutrals-800-shade};

			// --card-border-color: #{variables.$color-neutrals-700-shade};

			--card-active-background-color: #{variables.$color-neutrals-700-shade};
			--card-active-outline-color: #{variables.$color-cta-dark-active};
			--card-focus-outline-color: #{variables.$color-cta-dark-focus};
			--card-focus-background-color: #{variables.$color-neutrals-800-shade};
			--card-hover-background-color: #{variables.$color-neutrals-700-shade};
		}
	}

	&.shadow {
		&-s {
			@include mixins.shadow(s);
		}

		&-m {
			@include mixins.shadow(m);
		}

		&-l {
			@include mixins.shadow(l);
		}
	}

	&.border-radius {
		&-s {
			border-radius: variables.$border-radius-s;
		}

		&-m {
			border-radius: variables.$border-radius-m;
		}

		&-l {
			border-radius: variables.$border-radius-l;
		}
	}

	&.border {
		--card-active-outline-color: #{variables.$color-cta-active};

		outline: 1px solid var(--card-border-color);

		&.hover {
			&-s:hover {
				@include mixins.shadow-hover(s);
			}

			&-m:hover {
				@include mixins.shadow-hover(m);
			}

			&-l:hover {
				@include mixins.shadow-hover(l);
			}
		}

		&.dashed,
		&.dashed:active {
			outline-style: dashed !important;
		}
	}

	&.selected {
		outline: 2px solid variables.$color-cta-active;
		background-color: variables.$color-information-50-tint !important;
	}

	.badge-s,
	.badge-m,
	.badge-l {
		position: absolute;
		top: 0;
		left: variables.$border-radius-l;
	}

	@include mixins.breakpoint(mobile) {
		max-width: calc(100vw - #{variables.$spacing-xl});
	}
}
</style>
